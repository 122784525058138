import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdEmail, MdPhone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact-us"} />
		<Helmet>
			<title>
				🔥 The Mige Myi Sports Pub 🍺
			</title>
			<meta name={"description"} content={"Step into a fusion of rustic charm and sporting passion."} />
			<meta property={"og:title"} content={"🔥 The Mige Myi Sports Pub 🍺"} />
			<meta property={"og:description"} content={"Step into a fusion of rustic charm and sporting passion."} />
			<link rel={"shortcut icon"} href={"https://migemyi.com/img/1748081.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://migemyi.com/img/1748081.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://migemyi.com/img/1748081.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://migemyi.com/img/1748081.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://migemyi.com/img/1748081.png"} />
			<meta name={"msapplication-TileImage"} content={"https://migemyi.com/img/1748081.png"} />
			<meta name={"msapplication-TileColor"} content={"https://migemyi.com/img/1748081.png"} />
		</Helmet>
		<Components.Header />
		<Section
			color="--light"
			padding="100px 0"
			sm-padding="40px 0"
			position="relative"
			background="linear-gradient(0deg,rgba(25, 30, 34, 0.8) 0%,rgba(25, 30, 34, 0.8) 100%),--color-darkL2 url(https://migemyi.com/img/1.3.jpg) 0% 0%/contain"
			quarkly-title="Form-1"
		>
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box width="50%" padding="8px 8px 8px 8px" md-width="100%">
					<Box>
						<Text margin="0px 0px 0px 0px" font="--headline1">
							Contact us
						</Text>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="50%" md-width="100%">
					<Box>
						<Box
							sm-padding="64px 0 0 0"
							margin="32px 0 0 0"
							max-width="400px"
							position="relative"
							padding="0 0 0 64px"
						>
							<Icon
								size="48px"
								top="0"
								left="0"
								category="md"
								icon={MdLocationOn}
								position="absolute"
							/>
							<Text as="h4" margin="6px 0" font="--base">
								Visit us
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								473 Cypress Ave, Half Moon Bay, CA 94019, USA
							</Text>
						</Box>
						<Box
							position="relative"
							padding="0 0 0 64px"
							sm-padding="64px 0 0 0"
							margin="64px 0 0 0"
							max-width="360px"
						>
							<Icon
								top="0"
								left="0"
								category="md"
								icon={MdEmail}
								position="absolute"
								size="48px"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Email us
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								<Link href="mailto:contact@migemyi.com" text-decoration="none" hover-text-decoration="underline" color="--light">
									contact@migemyi.com
								</Link>
							</Text>
						</Box>
						<Box padding="0 0 0 64px" margin="64px 0 0 0" max-width="360px" position="relative">
							<Icon
								left="0"
								category="md"
								icon={MdPhone}
								position="absolute"
								size="48px"
								top="0"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Call us
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								+1 650-726-5006
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652e9dee47aa25001fb0714b"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});